
import Code from "@/core-ui/Code.vue";
import Header from "../components/core/Header.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";

export default {
    components: {
        Header,
        Code,
    },
    data: () => ({}),

    setup() {
        let message = `Login Successful. Copy the text below and paste it in the terminal where the login attempt originated.`;
        let code = "";
        const route = useRoute();
        if (route.query.code) {
            code = route.query.code as string;
        } else {
            message =
                "No OIDC auth code detected, either you reached this page by mistake or you should redo the auth flow.";
        }
        return {
            code,
            message,

            get title(): string {
                return "OIDC Auth Code";
            },
        };
    },
};
