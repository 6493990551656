import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Code = _resolveComponent("Code")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Header),
    _createElementVNode("section", {
      class: _normalizeClass(_ctx.$style.container)
    }, [
      _createElementVNode("p", null, _toDisplayString($setup.message), 1),
      _createVNode(_component_Code, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.code), 1)
        ]),
        _: 1
      })
    ], 2)
  ]))
}